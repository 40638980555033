import React from 'react';
import ReactDOM from 'react-dom';
import { DivOverallWrap } from '../../../../react/components/utility/wrap';
import { Top } from '../../../../react/pages/tops/show/top';


document.addEventListener('DOMContentLoaded', () => {
  const domToBinding = document.getElementById('react-tops-show-root')!;
  if(domToBinding == null) return

  const props = JSON.parse(domToBinding.dataset.json!);

  ReactDOM.render(
    <div style={{backgroundColor: '#f0f0f0'}}>
      <DivOverallWrap style={{paddingBottom: 0, backgroundColor: '#f0f0f0'}}>
        <Top {...props}/>
      </DivOverallWrap>
    </div>,
    domToBinding);
});

