import React from "react";
import styled from "styled-components";
import { DivFlexCen } from "../../../components/utility/flex";
import { ORANGE_SECONDARY, TEXT_SECONDARY } from "../../../config/Constants";
import keisanDojoIndexShowImagePath from '../../../../images/top/keisan_dojo_index_show-min.png';
import keisanDojoDrillResultImagePath from '../../../../images/top/keisan_dojo_drill_result-min.png';
import keisanPrintImagePath from '../../../../images/top/keisan_print-min.png';
import keisanClubSlackImagePath from '../../../../images/top/keisan_club_tensaku.png';
import keisanClubMarutsukeImagePath from '../../../../images/top/keisan_club_marutsuke-min.png';
import keisanClubGraphImagePath from '../../../../images/top/keisan_club_graph-min.png';
import keisanClubPrintImagePath from '../../../../images/top/keisan_club_print-min.png';

import { PTextPrimaryCenS, PTextPrimaryS } from "../../../components/utility/textPrimary";
import { ALink } from "../../../components/utility/link";

type Props = {
  drillSemiKeisanClubNotionUrl: string;
  drillSemiNotionUrl: string;
}

export const Top:FC<Props>= (props: Props) => {
  const { drillSemiKeisanClubNotionUrl, drillSemiNotionUrl } = props;

  return(
    <DivLpWrap>
      <DivTileWrap>
        <H1Tile>
          ドリる算数
        </H1Tile>
      </DivTileWrap>
      <DivBlock>
        <H2>
          「ドリる算数」「計算くらぶ」とは？
        </H2>
        <DivDescription>
          「ドリる算数」は、中学受験算数の教材の提供・採点・チャットシステムを備えた中学受験算数に特化したシステムです。
          <br/>
          <br/>
          「計算くらぶ」は、ドリる算数のシステムを使った、中学受験に特化した計算添削サービスです。
          <br/>
        </DivDescription>
      </DivBlock>
      <DivBlock>
        <H2>
          コンテンツ
        </H2>
        <DivStepWrap>
          <DivDescription>
            <SpanBold>・ドリる算数【無料で使えるアプリ】</SpanBold>
            <PTextPrimaryS style={{padding: '1rem'}}>中学受験算数に必要な基礎学力(計算・単元学習) を、必要なだけ鍛えることができます!
ダウンロードする度、新しい問題が出力される計算プリントで、無限に練習ができます。
アプリ上でのコンテストも！</PTextPrimaryS>
          </DivDescription>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExampleFull src={keisanDojoIndexShowImagePath}/>
            </DivImgWrap>
            <PCaption>中学受験に必要な計算をとりそろえております</PCaption>
          </DivImgAndCaptionWrap>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExampleFull src={keisanDojoDrillResultImagePath}/>
            </DivImgWrap>
            <PCaption>アプリで計算力を判定</PCaption>
          </DivImgAndCaptionWrap>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExampleFull src={keisanPrintImagePath}/>
            </DivImgWrap>
            <PCaption>毎回新しいプリントで計算練習が無限にできます</PCaption>
          </DivImgAndCaptionWrap>
        </DivStepWrap>
        <DivStepWrap>
          <DivDescription>
            <SpanBold>・計算くらぶ</SpanBold>
            <PTextPrimaryS style={{padding: '1rem'}}>中学受験レベルの計算力を鍛えるコミュニティーです。自動採点機能や問題ごとの結果画面。計算くらぶ生みんなで毎日、コツコツと計算力を鍛えます。<br/><ALink href={drillSemiKeisanClubNotionUrl} target='_blank' rel='oreferrer noopener'>詳細はこちら<i className="fas fa-external-link-alt"/></ALink></PTextPrimaryS>
          </DivDescription>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExampleFull src={keisanClubPrintImagePath}/>
            </DivImgWrap>
            <PCaption>計算くらぶの問題例</PCaption>
          </DivImgAndCaptionWrap>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExample src={keisanClubMarutsukeImagePath}/>
              <IArrowRight className="fas fa-arrow-right"/>
              <ImgExample src={keisanClubGraphImagePath}/>
            </DivImgWrap>
            <PCaption>自動採点画面と問題の正答率・結果画面</PCaption>
          </DivImgAndCaptionWrap>
          <DivImgAndCaptionWrap>
            <DivImgWrap>
              <ImgExampleFull src={keisanClubSlackImagePath}/>
            </DivImgWrap>
            <PCaption>計算くらぶのでのアドバイス</PCaption>
          </DivImgAndCaptionWrap>
        </DivStepWrap>
        <DivStepWrap>
          <H2>
            はじめかた
          </H2>
          <DivFlexCen style={{padding: '2rem'}}>
            <AButtonSecond href={'/question_sets?from=top'}>
              まずは、アプリをお試し(無料)
            </AButtonSecond>
          </DivFlexCen>
          <DivFlexCen style={{padding: '2rem'}}>
            <AButton href={'/menu/study?from=top'}>
              メニューへ
            </AButton>
          </DivFlexCen>
          <DivFlexCen style={{padding: '2rem'}}>
            <AButton href={'/users/new?from=top'}>
              こちらから登録！(無料)
            </AButton>
          </DivFlexCen>
        </DivStepWrap>
      </DivBlock>
    </DivLpWrap>
  )
}

const DivLpWrap = styled.div`
  background-color: #f0f0f0;
`;

const AButton = styled.a`
  color: #fff;
  background-color: #eb6100;
  border-bottom: 5px solid #b84c00;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  text-decoration: none;

  &:hover {
    margin-top: 3px;
    color: #fff;
    background: #f56500;
    border-bottom: 2px solid #b84c00;
  }
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
`

const AButtonSecond = styled.a`
  color: #eb6100;
  background-color: #fff;
  border-bottom: 5px solid #aaa;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  text-decoration: none;

  &:hover {
    margin-top: 3px;
    color: #eb6100;
    background: #fff;
    border-bottom: 2px solid #aaa;
  }
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
  box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
`

const DivStepWrap = styled.div`
  margin: 0 0 5rem 0;
`

const IArrowRight = styled.i`
  font-size: 2.5rem;
  color: ${ORANGE_SECONDARY}
`

const DivImgAndCaptionWrap = styled.div`
  padding: 1rem;
`


const DivImgWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const PCaption = styled(PTextPrimaryCenS)`
  margin-top: 0.5rem;
`

const ImgExample = styled.img`
  width: 40%;
  text-align: center;
`

const ImgExampleFull = styled.img`
  width: 80%;
  text-align: center;
`


const DivDescription = styled.div`
  margin: 1.5rem 0;
`

const DivBlock = styled.div`
  padding: 1rem 0.5rem 2rem;
`

const H2 = styled.h2`
  margin: 0 0 1rem 0;
  font-size: 1.25rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
`

const SpanBold = styled.span`
  font-weight: bold;
`

const H1Tile = styled.h1`
  text-align: center;
  font-size: 1.25rem;
`

const DivTileWrap = styled.div`
  padding: 1.5rem;
  background-color: #f0f0f0;
`
